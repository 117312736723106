<template>
  <div v-if="show" class="modal modal-large no-overflow show">
    <!--START: Header-->
    <ModalHeader :title="title" @closeModal="closeModal"></ModalHeader>
    <!--END: Header-->

    <!--START: Page Loader-->
    <div v-if="showOptions.preLoader" class="pre-loader-wrapper">
      <PreLoader :show="true"></PreLoader>
    </div>
    <!--START: Page Loader-->

    <!--START: Modal Body-->
    <div v-else class="modal-body">
      <!--START: Title-->
      <div
        class="title-wrapper"
        :class="{ 'show-shadow': showOptions.showShadow }"
      >
        <div class="title-info">
          <h3 class="title">Intent</h3>
          <p>Build custom replies with AI for intents</p>
        </div>
        <!--START: Attributes-->
        <div class="attributes-wrapper">
          <FormBuilder
            :fields="fields"
            @fieldChanged="fieldChanged"
          ></FormBuilder>
          <button
            class="btn btn-small btn-primary"
            :disabled="showOptions.disableButton || showOptions.saveInProgress"
            @click="saveIntent"
          >
            Save
          </button>
        </div>
        <!--END: Attributes-->
      </div>
      <!--END: Title-->

      <div class="main-wrapper" ref="documentContent" @scroll="contentScroll">
        <div class="settings-form-wrapper">
          <SettingsForm
            :fields="intentFields.fields"
            additionalClass="two-column-form"
            @fieldChanged="fieldChanged"
          ></SettingsForm>
        </div>
      </div>
    </div>
    <!--END: Modal Body-->

    <!--START: Notification Message-->
    <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
      @closeModal="closeModal"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Loader-->
    <LineLoader
      :show="showOptions.lineLoader"
      :class="{ bottom: !showOptions.modalActions }"
    ></LineLoader>
    <!--END: Loader-->
  </div>
</template>
                
<script>
// Import libraries
import _ from "lodash";

// Importing components
import ModalHeader from "@/components/modals/ModalHeader";
import LineLoader from "@/components/loaders/LineLoader";
import NotificationMessage from "@/components/modals/NotificationMessage";
import SettingsForm from "@/components/form/SettingsForm";
import FormBuilder from "@/components/form/FormBuilder";
import PreLoader from "@/components/loaders/PreLoader";

// Importing Services
import { QuestionCategoryService } from "@/services";

export default {
  name: "CategoryIntent",
  props: {
    show: { type: Boolean, required: true },
    questionCategoryID: { type: String, required: true },
    intent: { type: Object, required: true },
    customReplies: { type: Array, required: true },
  },
  watch: {
    show: {
      immediate: true,
      deep: true,
      handler() {
        this.initModal();
      },
    },
  },
  computed: {},
  data() {
    return {
      title: "Intent",
      showOptions: {
        lineLoader: false,
        showShadow: false,
        preLoader: true,
        disableButton: true,
      },
      fields: {
        isActive: {
          type: "toggle",
          title: "Active",
          additionalClass: "large-toggle",
          value: true,
        },
      },
      intentFields: {
        fields: {
          title: {
            type: "textarea",
            title: "Describe the intent*",
            required: true,
            hasError: false,
            placeholder: "Add an intent name",
            value: "",
          },
          customReplyID: {
            type: "dropdown",
            placeholder: "Select custom reply",
            title: "Custom reply to use",
            required: false,
            hasError: false,
            fields: [],
            value: [],
          },
        },
      },
      status: {
        show: false,
        status: "success",
        title: "Intent saved",
      },
      initData: {},
      dataChanged: false,
      saveInProgress: false,
    };
  },
  components: {
    ModalHeader,
    LineLoader,
    SettingsForm,
    FormBuilder,
    NotificationMessage,
    PreLoader,
  },
  created() {},

  methods: {
    initModal() {
      this.resetModal();
      this.getIntent();
    },

    // Get the intent
    getIntent() {
      this.customReplies.forEach((r) =>
        this.intentFields.fields.customReplyID.fields.push({
          name: r.name,
          value: r._id,
        })
      );
      this.intentFields.fields.customReplyID.fields.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      this.populateFields();
      this.showOptions.preLoader = false;
    },

    // Save intent
    async saveIntent() {
      if (!this.saveInProgress) {
        this.saveInProgress = true;
        this.showOptions.lineLoader = true;

        // Check if the form has valid input
        const data = { ...this.fields, ...this.intentFields.fields };
        var isFormValid = this.validateForm(data);
        if (isFormValid) {
          const payload = this.constructPayload(this.parseData(data));

          let service = "SaveIntent";
          if (payload.intent._id) service = "UpdateIntent";
          const response = await QuestionCategoryService[service](payload);

          // Check for errors
          if (!response.hasError) {
            this.$parent.intent = response.data;

            this.showStatusMessage(this.status, 2500);
            this.$emit("intentAdded", response.data);
          } else this.showErrorMessage(this.status, response.message);
        }

        this.showOptions.lineLoader = false;
        this.saveInProgress = false;
      }
    },

    // Populate the fields
    populateFields() {
      if (!_.isEmpty(this.intent)) {
        this.fields.isActive.value = this.intent.isActive;
        const { title, customReplyID } = this.intentFields.fields;

        title.value = this.intent.title;

        const selectedReply = this.customReplies.find(
          (m) => m._id === this.intent.customReplyID
        );
        if (selectedReply)
          customReplyID.value = [selectedReply.name, selectedReply._id];
      }

      this.initFormData();
    },

    // Initialise the form data for comparisons
    initFormData() {
      const data = {
        ...this.fields,
        ...this.intentFields.fields,
      };

      this.disableSaveButton(true);
      this.initData = _.cloneDeep(data);
    },

    // Show the modal header options
    disableSaveButton(status) {
      this.dataChanged = !status;
      this.showOptions.disableButton = status;
    },

    // Update persona when fields change
    fieldChanged() {
      const data = {
        ...this.fields,
        ...this.intentFields.fields,
      };
      this.disableSaveButton(this.isDataEqual(this.initData, data));
    },

    // Construct the payload for the controller
    constructPayload(data) {
      const payload = {
        questionCategoryID: this.questionCategoryID,
        intent: data,
      };
      if (this.intent._id) payload.intent._id = this.intent._id;
      return payload;
    },

    // Event on chat scroll
    contentScroll() {
      if (this.$refs.documentContent.scrollTop > 0)
        this.showOptions.showShadow = true;
      else this.showOptions.showShadow = false;
    },

    // Reset booking modal
    resetModal() {
      this.showOptions.preLoader = true;
      this.showOptions.lineLoader = false;
      this.showOptions.showShadow = false;
    },

    // Hide modal
    closeModal() {
      this.showOptions.lineLoader = false;
      this.$emit("closeModal");
    },
  },
};
</script>
                
  <style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

/deep/ .modal-header {
  padding: 0.5rem 1rem;

  /deep/ .unicon svg {
    background-color: lighten($blackColor, 15%);
    fill: $whiteColor;
  }
}

/deep/ .modal-actions {
  flex-direction: row;
  justify-content: center;
}

.modal-body {
  position: relative;
  padding: 0 !important;
  flex: 1;
  overflow: hidden;
  overflow-y: hidden !important;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

/deep/ .status-message {
  position: relative;
  bottom: auto;
  right: 0;
  left: 0;
  border-radius: 0;
  text-align: center;
}

/deep/ .line-loader {
  position: relative;
  bottom: auto;
  right: 0;
  left: 0;
}

.title-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.5rem 2rem;
  z-index: 5;

  &.show-shadow {
    box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
  }

  .title-info {
    flex: 1;
    .title {
      font-size: $mediumFontSize;
      text-transform: capitalize;
      margin-bottom: 0.15rem;
    }

    p {
      color: $paragraphColor;
      font-size: $smallFontSize;
    }
  }
}

.main-wrapper {
  padding: 2rem 3rem;
  background-size: 22px 22px;
  border-top: 1px solid $greyBorderColor;
  overflow-y: scroll;

  &.show-shadow {
    box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
  }
}

.attributes-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  /deep/ {
    .field-main-item {
      margin-bottom: 0;
    }
  }

  .btn {
    margin-left: 1.5rem;
  }
}

.pre-loader-wrapper {
  margin: 2rem 3rem;
}
</style>
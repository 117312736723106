<template>
  <div class="message-form card">
    <!--START: Intro-->
    <div class="intro-wrapper">
      <h3
        v-html="getFormattedContent(messageData.rechargeData.form.title)"
      ></h3>
      <p
        v-html="getFormattedContent(messageData.rechargeData.form.description)"
      ></p>
    </div>
    <!--END: Intro-->
    <DynamicForm
      class="form"
      :fields="messageData.rechargeData.dataModel.fields"
      :messageData="messageData"
      @saveData="saveForm"
    ></DynamicForm>
  </div>
</template>
    
<script>
// Importing components
import DynamicForm from "../Form/Forms/DynamicForm";

export default {
  name: "MessageForm",
  data() {
    return {};
  },
  props: {
    messageData: { type: Object, required: true },
  },
  watch: {},
  components: { DynamicForm },
  computed: {
    selectedSubscription() {
      return this.messageData.rechargeData.selectedSubscription;
    },
  },
  created() {
    this.initFields();
  },
  methods: {
    // Initialise the fields
    initFields() {
      const subscriptionDate =
        this.messageData.rechargeData.dataModel.fields.find(
          (f) => f.key === "subscriptionDate"
        );

      if (subscriptionDate) {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");

        subscriptionDate.minDates = `${year}-${month}-${day}`;
      }
    },

    // Event to save the data
    saveForm(payload) {
      this.$emit("saveForm", payload);
    },

    // Replace handlebars in the string
    getFormattedContent(string) {
      return this.replaceHandlebars(string, {
        selectedSubscription: this.selectedSubscription,
      });
    },
  },
};
</script>
        
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.intro-wrapper {
  margin-bottom: 0.75rem;

  h3 {
    color: lighten($darkBlackColor, 15%);
    font-size: $normalFontSize;
    margin-bottom: 0;
  }

  p {
    margin: 0.5rem 0 1rem;
    font-size: $smallerFontSize;
    color: $paragraphColor;
  }
}

.message-form.card {
  margin: 1rem 0;
  padding: 1.25rem;
  width: 75%;
  float: right;
}

.form /deep/ {
  .field-main-item {
    margin-bottom: 0.75rem;
  }

  .field-title {
    font-size: $smallestFontSize;
  }

  .btn-primary {
    display: block;
    width: 100%;
    font-size: $smallerFontSize;

    &:hover {
      span {
        color: var(--customer-text-color);
      }
      background: var(--customer-bg-color);
    }
  }
}
</style>
<template>
  <div>
    <div
      v-for="response in messages"
      :key="response._id"
      :class="`type-${response.messageType} ${
        response.eventType
          ? response.eventType.toLowerCase().replace('_', '-')
          : ''
      }`"
    >
      <!--START: Order Message-->
      <Order
        v-if="isOrderMessage(response)"
        :order="response.messageData.responseFields.order"
      ></Order>
      <!--END: Order Message-->

      <!--START: Subscription Message-->
      <Subscriptions
        v-else-if="isSubscriptionsMessage(response)"
        :message="response"
      ></Subscriptions>
      <SubscriptionForm
        v-else-if="isSubscriptionFormMessage(response)"
        :messageData="response.messageData"
      ></SubscriptionForm>
      <!--END: Subscription Message-->

      <!--START: Form-->
      <Form
        v-else-if="response.messageType === 'form'"
        :messageData="response.messageData"
        @saveForm="saveMessageInput"
      ></Form>
      <!--END: Form-->

      <!--START: Answer Bubbles-->
      <AnswerBubbles
        v-else-if="response.messageType === 'answer_bubbles'"
        :message="response"
        @saveAnswer="saveAnswerBubble"
      >
      </AnswerBubbles>
      <!--END: Answer Bubbles-->

      <!--START: Portal Button-->
      <CustomerPortal
        v-else-if="isCustomerPortalMessage(response)"
        :message="response"
      ></CustomerPortal>
      <!--END: Portal Button-->

      <!--START: Chat Message-->
      <ChatMessage
        v-else-if="isChatMessage(response)"
        :message="response"
        :aiAnalysis="aiAnalysis"
        :showSources="true"
      >
      </ChatMessage>
      <!--END: Chat Message-->

      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
// Importing Services
import "external-svg-loader";

// Importing components
import ChatMessage from "./ChatMessage/Index";
import AnswerBubbles from "./AnswerBubbles/Index";
import CustomerPortal from "./CustomerPortal";
import Subscriptions from "./Subscriptions/Index";
import SubscriptionForm from "./Subscriptions/Form";
import Order from "./Order/Index";
import Form from "./Form/Index";

export default {
  name: "Messages",
  data() {
    return {};
  },
  props: {
    messages: { type: Array, required: true },
    aiAnalysis: { type: Array, required: true },
  },
  watch: {},
  components: {
    ChatMessage,
    AnswerBubbles,
    CustomerPortal,
    Subscriptions,
    SubscriptionForm,
    Order,
    Form,
  },
  computed: {
    // Widget language
    widgetLanguage() {
      return this.languages[this.$store.getters.widgetLanguage].widget;
    },

    // Last chat message
    lastMessage() {
      return this.messages[this.messages.length - 1];
    },
  },
  created() {},
  methods: {
    // Save the chat message to message
    saveMessageInput(payload) {
      this.$emit("saveMessageInput", payload);
    },

    // Save the answer bubble option
    saveAnswerBubble(message, payload) {
      message.messageData = this.lastMessage.messageData;
      this.$emit("addChatMessage", message);
      this.saveMessageInput(payload);
    },

    // Check if it's a chat message type
    isChatMessage(response) {
      return (
        response.messageType !== "widget_event" &&
        response.messageType !== "integration_event" &&
        response.messageType !== "email_trigger" &&
        response.messageType !== "auth_event" &&
        response.messageType !== "condition"
      );
    },

    // Check if it's an order type
    isOrderMessage(response) {
      if (
        response.messageType === "integration_event" &&
        response.messageData.integrationEvent.appType === "shopify" &&
        response.messageData.integrationEvent.event == "show_order"
      )
        return true;
      return false;
    },

    // Check if it's a subscription type
    isSubscriptionsMessage(response) {
      if (
        response.messageType === "integration_event" &&
        response.messageData.integrationEvent.appType === "recharge" &&
        response.messageData.integrationEvent.event == "show_subscriptions"
      )
        return true;
      return false;
    },

    // Check if it's a subscription form type
    isSubscriptionFormMessage(response) {
      const allowedEvents = [
        "change_order_date_form",
        "change_subscription_frequency_form",
      ];
      if (
        response.messageType === "integration_event" &&
        response.messageData.integrationEvent.appType === "recharge" &&
        allowedEvents.includes(response.messageData.integrationEvent.event)
      )
        return true;
      return false;
    },

    // Check if it's a customer portal message
    isCustomerPortalMessage(response) {
      if (
        response.messageType === "widget_event" &&
        response.messageData.widgetEvent.event === "customer_account_portal"
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>
    
<style scoped lang="scss">
</style>
<template>
  <div class="page-main flex-page">
    <!--START: Header Wrapper-->
    <div
      class="header-wrapper"
      :class="{ 'show-shadow': showOptions.headerShadow }"
    >
      <!--START: Page Header-->
      <RegularHeader
        class="regular-header"
        :data="regularHeader"
      ></RegularHeader>
      <!--END: Page Header-->
      <button
        class="btn btn-small btn-primary"
        :disabled="showOptions.disableButton"
        @click="saveAiAgent"
      >
        Save Changes
      </button>
    </div>
    <!--END: Header Wrapper-->

    <div ref="settings" class="settings-main" @scroll="settingsScroll">
      <!--START: Settings Form-->
      <div class="settings-wrapper">
        <div class="settings-form-wrapper">
          <!--START: Pre Chat Accordion-->
          <div
            class="accordion-wrapper"
            :class="{ selected: selectedAccordion === 'preChat' }"
          >
            <div class="accordion-header" @click="toggleAccordion('preChat')">
              <div class="accordion-title">
                <unicon :name="preChat.icon"></unicon>
                <h3>{{ preChat.title }}</h3>
              </div>
              <unicon name="angle-down" class="icon"></unicon>
            </div>
            <div
              v-if="selectedWorkflows.preChat.length"
              class="selected-workflows"
              @click="toggleAccordion('preChat')"
            >
              <div
                v-for="workflow in selectedWorkflows.preChat"
                :key="workflow"
                class="selected-workflow"
              >
                <unicon name="check"></unicon>
                <span>{{ workflow }}</span>
              </div>
            </div>
            <div class="accordion-block">
              <SettingsForm
                :fields="preChat.fields"
                :content="preChat.content"
                @fieldChanged="fieldChanged"
              ></SettingsForm>
              <FormBuilder
                v-if="preChat.fields.preChatForm.fields.isActive.value"
                class="pre-chat-form"
                :fields="preChatFormFields"
                @fieldChanged="fieldChanged"
              ></FormBuilder>
            </div>
          </div>
          <!--END: Pre Chat Accordion-->

          <!--START: During Chat Accordion-->
          <div
            class="accordion-wrapper"
            :class="{ selected: selectedAccordion === 'duringChat' }"
          >
            <div
              class="accordion-header"
              @click="toggleAccordion('duringChat')"
            >
              <div class="accordion-title">
                <unicon :name="duringChat.icon"></unicon>
                <h3>{{ duringChat.title }}</h3>
              </div>
              <unicon name="angle-down" class="icon"></unicon>
            </div>
            <div
              v-if="selectedWorkflows.duringChat.length"
              class="selected-workflows"
              @click="toggleAccordion('duringChat')"
            >
              <div
                v-for="workflow in selectedWorkflows.duringChat"
                :key="workflow"
                class="selected-workflow"
              >
                <unicon name="check"></unicon>
                <span>{{ workflow }}</span>
              </div>
            </div>
            <div class="accordion-block">
              <SettingsForm
                :fields="duringChat.fields"
                :content="duringChat.content"
                @fieldChanged="fieldChanged"
              ></SettingsForm>
            </div>
          </div>
          <!--END: During Chat Accordion-->

          <!--START: Post Chat Accordion-->
          <div
            class="accordion-wrapper"
            :class="{ selected: selectedAccordion === 'postChat' }"
          >
            <div class="accordion-header" @click="toggleAccordion('postChat')">
              <div class="accordion-title">
                <unicon :name="postChat.icon"></unicon>
                <h3>{{ postChat.title }}</h3>
              </div>
              <unicon name="angle-down" class="icon"></unicon>
            </div>
            <div
              v-if="selectedWorkflows.postChat.length"
              class="selected-workflows"
              @click="toggleAccordion('postChat')"
            >
              <div
                v-for="workflow in selectedWorkflows.postChat"
                :key="workflow"
                class="selected-workflow"
              >
                <unicon name="check"></unicon>
                <span>{{ workflow }}</span>
              </div>
            </div>
            <div class="accordion-block">
              <SettingsForm
                :fields="postChat.fields"
                :content="postChat.content"
                @fieldChanged="fieldChanged"
              ></SettingsForm>
            </div>
          </div>
          <!--END: Post Chat Accordion-->
        </div>
      </div>
      <!--END: Settings Form-->
    </div>

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->

    <!--START: Notification Message-->
    <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
      @closeModal="closeModal"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Unsaved Changes Modal -->
    <UnsavedChangesModal
      :show="showOptions.unsavedChanges"
      @primaryEvent="continueRoute"
      @secondaryEvent="closeModal"
    ></UnsavedChangesModal>
    <!--END: Unsaved Changes Modal -->
  </div>
</template>
            
<script>
// Import libraries
import _ from "lodash";

// Importing components
import RegularHeader from "@/components/dashboard/RegularHeader";
import SettingsForm from "@/components/form/SettingsForm";
import FormBuilder from "@/components/form/FormBuilder";
import LineLoader from "@/components/loaders/LineLoader";
import NotificationMessage from "@/components/modals/NotificationMessage";
import UnsavedChangesModal from "@/components/modals/UnsavedChangesModal";

// Importing Services
import {
  AiAgentService,
  DataModelService,
  CustomReplyService,
} from "@/services";

export default {
  name: "AgentWorkflows",
  data() {
    return {
      lodash: _,
      showOptions: {
        headerShadow: false,
        lineLoader: false,
        unsavedChanges: false,
        disableButton: true,
      },
      dataPopulated: true,
      saveInProgress: false,
      selectedAccordion: "",
      regularHeader: {
        title: "Workflows",
        description:
          "Control how the AI agent handles workflows for different parts of the conversation",
      },
      preChat: {
        title: "Before a conversation begins",
        icon: "users-alt",
        content: {
          title: "Pre-Chat Workflows",
          description:
            "Tasks for the AI agent to perform before a conversation starts",
        },
        fields: {
          nudgeMessage: {
            type: "group",
            nestFields: true,
            fields: {
              isActive: {
                type: "toggle",
                title: "Show popup messages to the customer",
                additionalClass: "large-toggle",
                value: false,
              },
              showOnce: {
                type: "toggle",
                title: "Show the message only once",
                show: false,
                additionalClass: "large-toggle",
                value: false,
              },
              delay: {
                type: "number",
                title: "Show message after a delay in milliseconds",
                placeholder: "Add a value",
                additionalClass: "small-input",
                showEditButton: true,
                hasError: false,
                required: true,
                show: false,
                value: "",
              },
              message: {
                type: "text",
                title: "Message to show the customer",
                placeholder: "Add a message",
                required: false,
                hasError: false,
                showEditButton: true,
                show: false,
                value: "",
              },
            },
          },
          preChatForm: {
            type: "group",
            nestFields: true,
            fields: {
              isActive: {
                type: "toggle",
                title: "Capture customer details when chat starts",
                additionalClass: "large-toggle",
                value: false,
              },
              title: {
                type: "text",
                title: "Title of the form",
                placeholder: "Add a title",
                required: false,
                hasError: false,
                showEditButton: true,
                show: false,
                value: "",
              },
              description: {
                type: "text",
                title: "Description for the form",
                placeholder: "Add a description",
                required: false,
                hasError: false,
                showEditButton: true,
                show: false,
                value: "",
              },
              dataModelID: {
                type: "dropdown",
                placeholder: "Select model",
                title: "Data model to use",
                required: true,
                hasError: false,
                show: false,
                fields: [],
                value: [],
              },
            },
          },
        },
      },

      duringChat: {
        title: "During a conversation",
        icon: "comment-alt",
        content: {
          title: "Chat Workflows",
          description:
            "Tasks for the AI agent to perform while a conversation is active",
        },
        fields: {
          standardReplies: {
            title: "Reply buttons from the AI agent after every message",
            type: "group",
            nestFields: true,
            fields: {
              positiveReply: {
                type: "group",
                nestFields: true,
                fields: {
                  message: {
                    type: "text",
                    title: "This will resolve the conversation",
                    placeholder: "Add a message",
                    required: true,
                    hasError: false,
                    showEditButton: true,
                    value: "",
                  },
                  customReplyID: {
                    type: "dropdown",
                    placeholder: "Select custom reply",
                    title: "Custom reply for resolution",
                    required: true,
                    hasError: false,
                    fields: [],
                    value: [],
                  },
                },
              },

              negativeReply: {
                type: "group",
                nestFields: true,
                fields: {
                  message: {
                    type: "text",
                    title: "This will trigger the handoff workflow",
                    placeholder: "Add a message",
                    required: true,
                    hasError: false,
                    showEditButton: true,
                    value: "",
                  },
                  customReplyID: {
                    type: "dropdown",
                    placeholder: "Select custom reply",
                    title: "Custom reply for handoff",
                    required: true,
                    hasError: false,
                    fields: [],
                    value: [],
                  },
                },
              },
            },
          },
          showSources: {
            type: "group",
            nestFields: true,
            fields: {
              isActive: {
                type: "toggle",
                title: "Show sources of the AI generated answer",
                additionalClass: "large-toggle",
                value: false,
              },
            },
          },
        },
      },
      postChat: {
        title: "After a conversation ends",
        icon: "layers",
        content: {
          title: "Post-Chat Workflows",
          description:
            "Tasks for the AI agent to perform after a conversation has ended",
        },
        fields: {
          feedback: {
            type: "group",
            nestFields: true,
            fields: {
              isActive: {
                type: "toggle",
                title: "Get customers feedback after the conversation ends",
                additionalClass: "large-toggle",
                value: false,
              },
              positiveMessage: {
                type: "text",
                title: "Message for 3 star ratings and above",
                placeholder: "Add a message",
                required: false,
                hasError: false,
                showEditButton: true,
                show: false,
                value: "",
              },
              negativeMessage: {
                type: "text",
                title: "Message for ratings below 3",
                placeholder: "Add a message",
                required: false,
                hasError: false,
                showEditButton: true,
                show: false,
                value: "",
              },
            },
          },
        },
      },
      selectedWorkflows: {
        preChat: [],
        duringChat: [],
        postChat: [],
      },
      initData: {},
      dataModels: [],
      customReplies: [],
      dataChanged: false,
      status: {
        show: false,
        status: "success",
        title: "Workflows saved",
      },
    };
  },
  props: {
    aiAgent: { type: Object, required: true },
  },
  components: {
    RegularHeader,
    SettingsForm,
    FormBuilder,
    LineLoader,
    NotificationMessage,
    UnsavedChangesModal,
  },
  computed: {
    preChatFormFields() {
      const {
        preChatForm: { fields: preChatFormFields },
      } = this.preChat.fields;

      const fields = {};
      const dataModelID = preChatFormFields.dataModelID.value;
      if (dataModelID.length > 1) {
        const selectedModel = this.dataModels.find(
          (m) => m._id === dataModelID[1]
        );

        selectedModel.fields.forEach((f) => {
          fields[f.key] = {
            title: f.title,
            type: "toggle",
            additionalClass: "large-toggle",
            value: false,
          };
        });
      }

      return fields;
    },
  },
  watch: {
    aiAgent: {
      immediate: true,
      deep: true,
      handler() {
        this.populateFields();
      },
    },
  },
  async created() {
    this.initWorkflows();
  },
  methods: {
    // Initialise workflows
    async initWorkflows() {
      const {
        preChatForm: { fields: preChatFormFields },
      } = this.preChat.fields;

      const {
        standardReplies: {
          fields: { positiveReply, negativeReply },
        },
      } = this.duringChat.fields;

      // Get data models
      let response = await DataModelService.GetModels();
      if (!response.hasError) this.dataModels = response.data;
      this.dataModels.forEach((m) =>
        preChatFormFields.dataModelID.fields.push({
          name: m.name,
          value: m._id,
        })
      );

      // Get custome replies
      response = await CustomReplyService.GetAllReplies();
      if (!response.hasError) {
        this.customReplies = response.data;
        this.customReplies.forEach((r) => {
          positiveReply.fields.customReplyID.fields.push({
            name: r.name,
            value: r._id,
          });

          negativeReply.fields.customReplyID.fields.push({
            name: r.name,
            value: r._id,
          });
        });

        positiveReply.fields.customReplyID.fields.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        negativeReply.fields.customReplyID.fields.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      }

      this.dataPopulated = false;
      this.populateFields();
    },

    // Save widget
    async saveAiAgent() {
      if (!this.saveInProgress) {
        this.saveInProgress = true;
        this.showOptions.lineLoader = true;

        // Check if the form has valid input
        const data = {
          ...this.preChat.fields,
          ...this.duringChat.fields,
          ...this.postChat.fields,
        };
        var isFormValid = this.validateForm(data);
        if (isFormValid && this.isCustomerFormValid()) {
          const formData = this.constructPayload(_.cloneDeep(data));
          const response = await AiAgentService.SaveAiAgent(formData);

          // Check for errors
          if (!response.hasError) {
            this.$emit("aiAgentSaved", response.data);
            this.dataPopulated = false;

            this.status.title = response.message;
            this.showStatusMessage(this.status, 2500);
          } else this.showErrorMessage(this.status, response.message);
        }

        this.showOptions.lineLoader = false;
        this.saveInProgress = false;
      }
    },

    // Check if the customer form has atleast one toggle selected if the form is active
    isCustomerFormValid() {
      const {
        preChatForm: {
          fields: { isActive },
        },
      } = this.preChat.fields;

      let isValid = false;
      if (isActive.value) {
        for (var field in this.preChatFormFields) {
          if (this.preChatFormFields[field].value) isValid = true;
        }
      } else {
        isValid = true;
      }

      if (!isValid)
        this.showErrorMessage(
          this.status,
          "Atleast one field should be active in the customer form"
        );

      return isValid;
    },

    // Construct the payload for the controller
    constructPayload(fields) {
      const data = { workflows: this.parseData(fields) };

      const preChatFields = [];
      const chatFormFields = _.cloneDeep(this.preChatFormFields);
      Object.keys(chatFormFields).forEach(function (key) {
        if (chatFormFields[key].value) {
          preChatFields.push({
            title: chatFormFields[key].title,
            key: key,
            fieldType: "text",
            required: true,
          });
        }
      });

      data.workflows.preChatForm.fields = _.cloneDeep(preChatFields);

      let formData = new FormData();
      for (var key in data) formData.append(key, JSON.stringify(data[key]));

      return formData;
    },

    // Toggle the accordion view
    toggleAccordion(accordion) {
      if (this.selectedAccordion === accordion) this.selectedAccordion = "";
      else this.selectedAccordion = accordion;
    },

    // Populate the data for the fields
    async populateFields() {
      if (!this.dataPopulated) {
        this.dataPopulated = true;

        const { workflows } = this.aiAgent;
        const {
          preChatForm: { fields: preChatFormFields },
          nudgeMessage: { fields: nudgeMessageFields },
        } = this.preChat.fields;
        const {
          standardReplies: { fields: standardRepliesFields },
          showSources: { fields: showSourcesFields },
        } = this.duringChat.fields;
        const {
          feedback: { fields: feedbackFields },
        } = this.postChat.fields;

        // Pre chat form
        preChatFormFields.isActive.value = workflows.preChatForm.isActive;
        preChatFormFields.title.value = workflows.preChatForm.title;
        preChatFormFields.description.value = workflows.preChatForm.description;
        const selectedDataModel = this.dataModels.find(
          (m) => m._id === workflows.preChatForm.dataModelID
        );
        if (selectedDataModel) {
          preChatFormFields.dataModelID.value = [
            selectedDataModel.name,
            selectedDataModel._id,
          ];
        }

        workflows.preChatForm.fields.forEach((f) => {
          if (f.key) this.preChatFormFields[f.key].value = true;
        });

        // Nudge message
        nudgeMessageFields.isActive.value = workflows.nudgeMessage.isActive;
        nudgeMessageFields.message.value = workflows.nudgeMessage.message;
        nudgeMessageFields.showOnce.value = workflows.nudgeMessage.showOnce;
        nudgeMessageFields.delay.value = workflows.nudgeMessage.delay;

        // Standard replies
        // Positive reply message and custom reply
        standardRepliesFields.positiveReply.fields.message.value =
          workflows.standardReplies.positiveReply.message;
        let selectedReply = this.customReplies.find(
          (m) => m._id === workflows.standardReplies.positiveReply.customReplyID
        );
        if (selectedReply) {
          standardRepliesFields.positiveReply.fields.customReplyID.value = [
            selectedReply.name,
            selectedReply._id,
          ];
        }

        // Negative reply message and custom reply
        standardRepliesFields.negativeReply.fields.message.value =
          workflows.standardReplies.negativeReply.message;
        selectedReply = this.customReplies.find(
          (m) => m._id === workflows.standardReplies.negativeReply.customReplyID
        );
        if (selectedReply) {
          standardRepliesFields.negativeReply.fields.customReplyID.value = [
            selectedReply.name,
            selectedReply._id,
          ];
        }

        // Show sources
        showSourcesFields.isActive.value = workflows.showSources.isActive;

        // Feedback
        feedbackFields.isActive.value = workflows.feedback.isActive;
        feedbackFields.positiveMessage.value =
          workflows.feedback.positiveMessage;
        feedbackFields.negativeMessage.value =
          workflows.feedback.negativeMessage;

        this.updateSelectedWorkflows();
        this.handleFieldVisibility();
        this.initFormData();
      }
    },

    // Edit fields for prechat handler
    handleFieldVisibility() {
      const {
        preChatForm: { fields: preChatFormFields },
        nudgeMessage: { fields: nudgeMessageFields },
      } = this.preChat.fields;
      const {
        feedback: { fields: feedbackFields },
      } = this.postChat.fields;

      Object.keys(preChatFormFields).forEach(function (key) {
        if (key !== "isActive") {
          preChatFormFields[key].show = preChatFormFields.isActive.value;
        }
      });

      Object.keys(nudgeMessageFields).forEach(function (key) {
        if (key !== "isActive") {
          nudgeMessageFields[key].show = nudgeMessageFields.isActive.value;
        }
      });

      Object.keys(feedbackFields).forEach(function (key) {
        if (key !== "isActive") {
          feedbackFields[key].show = feedbackFields.isActive.value;
        }
      });
    },

    // Initialise the form data for comparisons
    initFormData() {
      this.disableSaveButton(true);
      this.initData = _.cloneDeep({
        preChat: this.preChat,
        preChatFormFields: this.preChatFormFields,
        duringChat: this.duringChat,
        postChat: this.postChat,
      });
    },

    // Update workflows summart
    updateSelectedWorkflows() {
      const {
        preChatForm: { fields: preChatFormFields },
        nudgeMessage: { fields: nudgeMessageFields },
      } = this.preChat.fields;
      const {
        standardReplies: { fields: standardRepliesFields },
        showSources: { fields: showSourcesFields },
      } = this.duringChat.fields;
      const {
        feedback: { fields: feedbackFields },
      } = this.postChat.fields;

      const preChatWorkflows = [];
      if (preChatFormFields.isActive.value)
        preChatWorkflows.push(preChatFormFields.isActive.title);
      if (nudgeMessageFields.isActive.value)
        preChatWorkflows.push(nudgeMessageFields.isActive.title);

      const duringChatWorkflows = [];
      duringChatWorkflows.push(
        `After every message reply with - "${standardRepliesFields.positiveReply.fields.message.value}" and 
        "${standardRepliesFields.negativeReply.fields.message.value}"`
      );
      if (showSourcesFields.isActive.value)
        duringChatWorkflows.push(showSourcesFields.isActive.title);

      const postChatWorkflows = [];
      if (feedbackFields.isActive.value)
        postChatWorkflows.push(feedbackFields.isActive.title);

      this.selectedWorkflows = {
        preChat: preChatWorkflows,
        duringChat: duringChatWorkflows,
        postChat: postChatWorkflows,
      };
    },

    // Event when field changed
    fieldChanged() {
      this.handleFieldVisibility();
      this.updateSelectedWorkflows();
      const data = {
        preChat: this.preChat,
        preChatFormFields: this.preChatFormFields,
        duringChat: this.duringChat,
        postChat: this.postChat,
      };
      this.disableSaveButton(this.isDataEqual(this.initData, data));
    },

    // Show the modal header options
    disableSaveButton(status) {
      this.dataChanged = !status;
      this.showOptions.disableButton = status;
    },

    // Event on settings scroll
    settingsScroll() {
      const scrollTop = this.$refs.settings.scrollTop;

      // Check if header needs shadow
      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },

    // Close all the modals
    closeModal() {
      this.status.show = false;
      this.showOptions.unsavedChanges = false;
    },

    // Discard all the changes
    discardChanges() {
      this.fields = _.cloneDeep(this.initData);
      this.disableSaveButton(true);
      this.closeModal();
    },

    // Discard the changes and move to the selected route
    continueRoute() {
      this.closeModal();
      this.discardChanges();
      if (this.toRoute != null) this.$router.push({ path: this.toRoute.path });
    },
  },
  // Check if there are any changes to be saved
  beforeRouteLeave(to, from, next) {
    if (this.dataChanged) {
      this.toRoute = to;
      this.showOptions.unsavedChanges = true;
    } else {
      next();
    }
  },
};
</script>
            
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.header-wrapper {
  padding-bottom: 1.5rem;
  margin-bottom: 0 !important;
  border-bottom: 1px solid darken($rowBorderColor, 2.5%);
  position: relative;
  z-index: 2;

  .regular-header {
    flex: 1;
    max-width: 70%;
    margin-right: 30%;
  }
}

.settings-main {
  overflow-y: scroll;
  padding: 1.5rem 1rem;
  flex: 1;
}

.accordion-title {
  .unicon /deep/ svg {
    background: $darkBlackColor;
    border: 1px solid lighten($darkBlackColor, 15%);
    fill: $whiteColor;
    height: auto;
    width: 1rem;
    padding: 0.35rem;
    border-radius: 0.5rem;
    margin-right: 0.75rem;
  }
}

.accordion-block {
  .settings-form {
    margin-bottom: 0;
    padding: 0;
    border: none;
  }
}

.selected-workflows {
  cursor: pointer;
  margin: 0 4.5rem;

  .selected-workflow {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 1.5rem;

    &::before {
      content: "";
      position: absolute;
      top: 1.25rem;
      left: 0.5rem;
      height: calc(100% - 1.25rem);
      width: 1px;
      border-left: 1px dashed $greyColor;
      opacity: 0.25;
    }

    .unicon /deep/ svg {
      background: lighten($greenColor, 25%);
      border: 1px solid lighten($greenColor, 15%);
      fill: darken($greenColor, 30%);
      padding: 0.05rem;
      height: auto;
      width: 0.85rem;
      border-radius: 50%;
      margin-right: 0.75rem;
      margin-top: 2px;
    }

    span {
      font-size: $smallerFontSize;
      color: $greyColor;
    }

    &:last-child {
      padding-bottom: 2rem;

      &::before {
        display: none;
      }
    }
  }
}

.accordion-wrapper {
  &.selected {
    .accordion-title {
      .unicon /deep/ svg {
        background: $purpleColor;
        border: 1px solid lighten($purpleColor, 15%);
      }
    }

    .selected-workflows {
      display: none;
    }
  }
}

.pre-chat-form {
  margin-left: 25rem;
}
</style>
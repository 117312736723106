<template>
  <div
    class="modal modal-large show"
    :class="{ 'modal-success': showOptions.state == 'success' }"
  >
    <!--START: Header-->
    <ModalHeader :title="title" @closeModal="closeModal"></ModalHeader>
    <!--END: Header-->

    <!--START: Modal Body-->
    <div class="modal-body modal-header-body">
      <div class="internal-modal-body">
        <!--START: Title-->
        <div
          class="title-wrapper"
          :class="{ 'show-shadow': showOptions.headerShadow }"
        >
          <div class="title-info">
            <h3 class="title">{{ modalContent.title }}</h3>
            <p>{{ modalContent.description }}</p>
          </div>
          <div class="actions-wrapper">
            <button
              class="btn btn-small btn-primary"
              :disabled="showOptions.disableButton"
              @click="saveCustomReply"
            >
              Save
            </button>
          </div>
        </div>
        <!--END: Title-->

        <!--START: PreLoader-->
        <div v-if="showOptions.preLoader" class="pre-loader-wrapper">
          <PreLoader :show="true"></PreLoader>
        </div>
        <!--START: PreLoader-->

        <div v-else ref="scrollBody" class="scroll-body" @scroll="bodyScroll">
          <FormBuilder
            :fields="fields"
            class="form-builder-main"
            @fieldChanged="fieldChanged"
          ></FormBuilder>
        </div>
      </div>
    </div>
    <!--END: Modal Body-->

    <!--START: Notification Message-->
    <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
      @closeModal="closeModal"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader" class="bottom"></LineLoader>
    <!--END: Loader-->
  </div>
</template>
                  
<script>
// Import libraries
import _ from "lodash";

// Importing components
import ModalHeader from "@/components/modals/ModalHeader";
import FormBuilder from "@/components/form/FormBuilder";
import LineLoader from "@/components/loaders/LineLoader";
import NotificationMessage from "@/components/modals/NotificationMessage";
import PreLoader from "@/components/loaders/PreLoader";

// Importing Services
import { WidgetSettingsService, CustomReplyService } from "@/services";

export default {
  name: "ViewCustomReply",
  props: {
    reply: { type: Object, required: true },
  },
  watch: {
    show: {
      immediate: true,
      deep: true,
      handler() {
        this.initModal();
      },
    },
  },
  computed: {},
  data() {
    return {
      title: "Add Custom Reply",
      modalContent: {
        title: "Add Custom Reply",
        description: "Add a custom replies to execute specific flows",
      },
      showOptions: {
        state: "form",
        disableButton: true,
        headerShadow: false,
        lineLoader: false,
        preLoader: true,
      },
      fields: {
        iconTitle: {
          type: "icon-selector",
          title: "Select an icon and title",
          placeholder: "Add the title",
          required: true,
          hasError: false,
          value: { icon: "", title: "" },
        },
        description: {
          type: "text",
          title: "Description",
          placeholder: "Add a description for the link",
          required: true,
          hasError: false,
          value: "",
        },
        customReplyID: {
          type: "dropdown",
          placeholder: "Select custom reply",
          title: "Custom reply to use",
          required: true,
          hasError: false,
          fields: [],
          value: [],
        },
      },
      initComplete: false,
      customReplies: [],
      replyID: null,
      initData: {},
      status: {
        show: false,
        status: "success",
        title: "Custom reply saved",
      },
    };
  },
  components: {
    ModalHeader,
    FormBuilder,
    LineLoader,
    PreLoader,
    NotificationMessage,
  },
  created() {
    this.initModal();
  },

  methods: {
    async initModal() {
      if (!this.initComplete) {
        this.initComplete = true;
        this.replyID = this.reply._id;

        const response = await CustomReplyService.GetAllReplies();
        if (!response.hasError) {
          this.customReplies = response.data;
          this.customReplies.forEach((r) =>
            this.fields.customReplyID.fields.push({
              name: r.name,
              value: r._id,
            })
          );

          this.fields.customReplyID.fields.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
        }

        this.populateData();
      }
    },

    populateData() {
      if (this.replyID) {
        const { iconTitle, description, customReplyID } = this.fields;

        iconTitle.value.title = this.reply.title;
        iconTitle.value.icon = this.reply.icon;
        description.value = this.reply.description;

        const selectedReply = this.customReplies.find(
          (m) => m._id === this.reply.customReplyID
        );
        if (selectedReply)
          customReplyID.value = [selectedReply.name, selectedReply._id];
      }

      this.initFormData();
      this.showOptions.preLoader = false;
    },

    async saveCustomReply() {
      if (!this.showOptions.lineLoader) {
        this.showOptions.lineLoader = false;
        const data = { ...this.fields };
        var isFormValid = this.validateForm(data);

        if (isFormValid) {
          this.showOptions.lineLoader = true;
          const payload = this.constructPayload();

          const response = await WidgetSettingsService.SaveCustomReply(payload);

          if (!response.hasError) {
            this.replyID = response.data._id;
            this.showStatusMessage(this.status, 2500);
            this.$emit("replyUpdated", response.data);
            this.initFormData();
          } else this.showErrorMessage(this.status, response.message);
        }

        this.showOptions.lineLoader = false;
      }
    },

    // Construct the payload for the controller
    constructPayload() {
      let payload = {
        replyID: this.replyID,
        ...this.parseData(this.fields),
      };

      payload = { ...payload, ...payload.iconTitle };
      delete payload.iconTitle;

      return payload;
    },

    // Initialise the form data for comparisons
    initFormData() {
      this.disableSaveButton(true);
      this.initData = _.cloneDeep(this.fields);
    },

    // Event when field changed
    fieldChanged() {
      this.disableSaveButton(this.isDataEqual(this.initData, this.fields));
    },

    // Show the modal header options
    disableSaveButton(status) {
      this.showOptions.disableButton = status;
    },

    bodyScroll() {
      const scrollTop = this.$refs.scrollBody.scrollTop;

      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },

    closeModal() {
      this.showOptions.lineLoader = false;
      this.$emit("closeModal");
    },
  },
};
</script>
                  
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.modal-body,
.internal-modal-body,
.scroll-body {
  overflow: visible !important;
}

.message-fields-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;

  .message-field-form {
    flex: 1;
  }

  .message-actions {
    transform: translateY(-0.25rem);
    .unicon /deep/ {
      svg {
        cursor: pointer;
        height: auto;
        width: 0.75rem;
        fill: $greyColor;
        margin-left: 0.5rem;
      }
    }
  }
}

.form-builder-main {
  /deep/ .field-block-item {
    .field-main-item:last-child {
      border-bottom: 1px solid $inputBorderColor;
      margin-bottom: 0.75rem;
      padding-bottom: 0.75rem;
    }
  }
}

.pre-loader-wrapper {
  margin: 2rem 3rem;
}
</style>
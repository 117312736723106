<template>
  <!--START: Chat Window-->
  <div class="chat-wrapper">
    <!--START: Chat Window-->
    <div
      class="chat-header show-shadow"
      :class="{ 'show-shadow': showOptions.chatHeaderShadow }"
    >
      <div class="chat-window-header">
        <div class="customer-name">
          <h3>{{ getCustomerName(chat).name }}</h3>
          <span
            >Ticket ID -
            {{ chat.ticketID ? `#${chat.ticketID}` : chat._id }}</span
          >
        </div>
        <div class="chat-actions-wrapper">
          <span v-if="chat.isFlagged" class="status red status-flagged"
            >Flagged</span
          >
          <span class="status" :class="getStatus(chat).class">{{
            getStatus(chat).title
          }}</span>

          <div
            class="action-btn"
            :class="{ resolved: chat.resolved }"
            @click="toggleResolution(chat._id)"
          >
            <span>{{ chat.resolved ? "Reopen chat" : "Resolve chat" }}</span>
            <unicon
              name="check-circle"
              :icon-style="chat.resolved ? 'solid' : 'line'"
            ></unicon>
          </div>
          <div
            class="action-btn"
            :class="{ flagged: chat.isFlagged }"
            @click="toggleFlag(chat._id)"
          >
            <span>{{ chat.isFlagged ? "Remove flag" : "Flag chat" }}</span>
            <unicon name="exclamation-circle"></unicon>
          </div>
          <div class="action-btn" @click="markUnread(chat._id)">
            <span>Mark as unread</span>
            <unicon name="envelope"></unicon>
          </div>
          <div class="action-btn" @click="showDeleteModal(chat._id, 'chat')">
            <span>Delete</span>
            <unicon name="trash-alt"></unicon>
          </div>
        </div>
      </div>

      <!--START: Categories-->
      <div class="category-wrapper">
        <div
          v-for="c in getCategories(chat)"
          :key="c.id"
          :class="c.type"
          class="category"
        >
          <unicon
            v-if="c.type !== 'ai-category'"
            :name="getCategoryIcon(c.type)"
          ></unicon>
          <svg
            v-else
            :data-src="require('@/assets/images/icons/ai-sparkle.svg')"
            height="12px"
            width="12px"
            class="ai-icon"
          ></svg>
          <span>{{ c.title }}</span>
        </div>
      </div>
      <!--END: Categories-->
    </div>
    <!--END: Chat Window-->

    <!--START: Messages Wrapper-->
    <div ref="chatWindow" class="chat-messages-wrapper" @scroll="chatScroll">
      <!--START: Pre Chat Details-->
      <PreChatForm v-if="showPreChatDetails" :chat="chat"></PreChatForm>
      <!--END: Pre Chat Details-->

      <!--START: Messages-->
      <Messages
        :messages="chat.messages"
        :aiAnalysis="chat.aiAnalysis"
      ></Messages>
      <!--END: Messages-->

      <!--START: Message End-->
      <div class="message-date message-end">
        <span>End of conversation</span>
      </div>
      <!--END: Message End-->
    </div>
    <!--END: Messages Wrapper-->

    <!--START: Confirmation Modal-->
    <ConfirmationModal
      :fields="confirmationFields"
      @primaryEvent="deleteItem"
      @secondaryEvent="closeModal"
    ></ConfirmationModal>

    <div
      class="bg-overlay light"
      :class="{ show: confirmationFields.show }"
      @click="closeModal"
    ></div>
    <!--END: Confirmation Modal-->
  </div>
  <!--END: Chat Window-->
</template>

<script>
// Import libraries
import _ from "lodash";
import "external-svg-loader";

// Import components
import PreChatForm from "./PreChatForm";
import Messages from "./Messages/Index";
import ConfirmationModal from "@/components/modals/ConfirmationModal";

import {
  showLastMessage,
  constructChat,
  createMessageDates,
} from "@/utils/chat.utils";

export default {
  name: "SelectedChat",
  data() {
    return {
      showOptions: {
        chatHeaderShadow: false,
        confirmationFields: false,
      },
      deleteID: null,
      confirmationFields: {
        show: false,
        title: "",
        type: "delete",
        modalType: "fixed",
        message: "This action cannot be undone.",
        iconType: "exclamation",
        confirmAction: true,
        confirmValue: null,
        primaryBtn: {
          title: "Yes. Delete",
        },
        secondaryBtn: {
          title: "Cancel",
        },
      },
      messageDates: [],
    };
  },
  props: {
    chat: { type: Object, required: true },
  },
  watch: {
    chat: {
      immediate: true,
      handler() {
        this.initChat();
      },
    },
  },
  components: {
    PreChatForm,
    Messages,
    ConfirmationModal,
  },
  computed: {
    chatMessages() {
      let messages = [];
      if (this.chat?.messages?.length) {
        messages = this.constructChat(this.chat.messages);
        this.constructMessageDates();
      }
      return messages;
    },

    categories() {
      return this.$store.getters.categories;
    },

    showPreChatDetails() {
      return this.chat.workflows?.preChat?.isCompleted;
    },
  },
  async mounted() {
    this.initChat();
  },

  methods: {
    // Core chat functions
    showLastMessage,
    constructChat,
    createMessageDates,

    // Initialise the chat
    initChat() {
      this.showLastMessage(this.$refs.chatWindow);
    },

    // Construct the message dates
    constructMessageDates() {
      this.messageDates.splice(0);
      this.messageDates = this.createMessageDates(this.chat);
    },

    // Flag the chat
    toggleResolution(chatID) {
      this.$emit("toggleResolution", chatID);
    },

    // Flag the chat
    toggleFlag(chatID) {
      this.$emit("toggleFlag", chatID);
    },

    // Mark the chat as unread
    markUnread(chatID) {
      this.$emit("markUnread", chatID);
    },

    // Show the delete modal
    showDeleteModal(itemID, itemTitle) {
      this.deleteID = itemID;
      this.confirmationFields.confirmValue = itemTitle;
      this.confirmationFields.show = true;
      this.confirmationFields.title = `Delete ${itemTitle}?`;
    },

    // Delete the chat
    async deleteItem() {
      this.$emit("deleteChat", this.deleteID);
    },

    // Get the customer name or email
    getCustomerName(chat) {
      let name = "Customer";
      let email = null;

      const customer = chat.dataModels?.find((d) => d.name === "Customer");
      if (!_.isEmpty(customer) && customer.fields.name) {
        name = customer.fields.name;
        email = customer.fields.email;
      } else if (!_.isEmpty(customer) && customer.fields.email)
        name = customer.fields.email;

      return { name, email };
    },

    // Get chat categories
    getCategories(chat) {
      const categories = [];

      // Extract the AI categories
      let aiCategories = chat?.aiAnalysis?.filter(function (r) {
        return r !== undefined;
      });
      aiCategories = _.uniqBy(aiCategories, "questionCategory");
      if (aiCategories.length > 0) {
        aiCategories.forEach((a) => {
          if (!_.isEmpty(a)) {
            categories.push({ title: a.questionCategory, type: "ai-category" });
          }
        });
      }

      // Extract the custom replies
      let customReplies = [
        ...new Set(
          chat.messages.map((m) => {
            if (m.messageData?.customReplyID)
              return m.messageData?.customReplyID;
          })
        ),
      ];
      customReplies = customReplies.filter(function (r) {
        return r !== undefined;
      });

      customReplies.forEach((r) => {
        const reply = this.categories.customReplies?.find((cr) => cr._id === r);
        if (reply) {
          categories.push({ title: reply.name, type: "custom-reply" });
        }
      });

      // Extract the suggested questions
      let suggestedQuestions = [
        ...new Set(
          chat.messages.map((m) => {
            if (m.messageData?.questionID) return m.messageData?.questionID;
          })
        ),
      ];
      suggestedQuestions = suggestedQuestions.filter(function (q) {
        return q !== undefined;
      });

      suggestedQuestions.forEach((q) => {
        const question = this.categories.suggestedQuestions?.find(
          (sq) => sq._id === q
        );
        if (question) {
          categories.push({
            title: question.question,
            type: "suggested-question",
          });
        }
      });

      return categories;
    },

    // Get the icon for the category
    getCategoryIcon(category) {
      switch (category) {
        case "ai-category":
          return "copy-alt";
        case "suggested-question":
          return "comment-alt";
        case "custom-reply":
          return "exchange";
        default:
          return "copy-alt";
      }
    },

    // Get chat status
    getStatus(chat) {
      let status = { title: "Open", class: "yellow" };
      if (chat.resolved) status = { title: "Resolved", class: "green" };
      else if (chat.resolved === false) {
        if (chat.status === "ticket")
          status = { title: "Ticket", class: "red" };
      }
      return status;
    },

    // Event on chat scroll
    chatScroll() {
      const scrollTop = this.$refs.chatWindow.scrollTop;

      if (scrollTop > 0) this.showOptions.chatHeaderShadow = true;
      else this.showOptions.chatHeaderShadow = false;
    },

    // Close all the modals
    closeModal() {
      this.confirmationFields.show = false;
    },
  },
};
</script>
    
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
@import "@/assets/styles/shared/chat.scss";

.chat-header-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.75rem 0;
  border-bottom: 1px solid $greyBorderColor;

  .inbox-header {
    width: calc(19.5rem - 3rem);
    padding: 0 1.5rem;

    label {
      color: $greyColor;
      font-size: $smallerFontSize;
      font-weight: $boldFontWeight;
    }
  }
}

.chat-header {
  position: relative;
  background-color: $whiteColor;
  padding: 0.75rem 1.5rem;
  z-index: 3;
}

.chat-window-header {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 3rem;

  .customer-name {
    flex: 1;
    overflow: hidden;
    h3 {
      font-size: $normalFontSize;
      margin-bottom: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: calc(100% - 2rem);
    }

    span {
      color: $greyColor;
      font-size: $smallestFontSize;
    }
  }

  .chat-actions-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .status {
      position: relative;
      font-size: $smallestFontSize;
      font-weight: $mediumFontWeight;

      padding: 0.25rem 0.5rem;
      border-radius: 0.5rem;
      text-transform: capitalize;
      margin-right: 1rem;
      margin-left: 0.5rem;

      &.green {
        color: $greenMessageTextColor;
        background-color: lighten($greenMessageColor, 0%);
        border: 1px solid $greenMessageBorderColor;
      }

      &.yellow {
        color: $warningYellowColor;
        background-color: darken($warningYellowBackgroundColor, 5%);
        border: 1px solid $warningYellowBorderColor;
      }

      &.red {
        color: $redMessageTextColor;
        background-color: lighten($redMessageColor, 2.5%);
        border: 1px solid $redMessageBorderColor;
      }

      &::after {
        position: absolute;
        content: "";
        right: -1rem;
        top: 50%;
        width: 1px;
        height: 70%;
        transform: translateY(-50%);
        background-color: $greyBorderColor;
      }

      &.status-flagged {
        margin-right: 0;
        &::after {
          display: none;
        }
      }
    }

    .action-btn {
      position: relative;
      margin-left: 0.75rem;

      span {
        display: none;
        position: absolute;
        color: $whiteColor;
        background-color: $darkBlackColor;
        font-size: $smallestFontSize;
        top: calc(100% + 0.5rem);
        left: 50%;
        transform: translateX(-50%);
        padding: 0.25rem 0.5rem;
        border-radius: 0.5rem;
        white-space: nowrap;
      }

      &:hover {
        span {
          display: block;
        }
      }

      &.resolved {
        .unicon {
          /deep/ svg {
            fill: $emeraldColor;
          }
        }
      }

      &.ticket,
      &.flagged {
        .unicon {
          /deep/ svg {
            fill: $redColor;
          }
        }
      }
    }
    .unicon {
      cursor: pointer;

      /deep/ svg {
        fill: $greyColor;
        height: auto;
        width: 1.25rem;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          fill: $purpleColor;
        }
      }
    }
  }
}
.chat-wrapper {
  background-color: $whiteColor;
  width: calc(100% - 20rem);
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  .chat-messages-wrapper {
    overflow-y: scroll;
    padding: 1rem 2.5rem 3rem;
    height: calc(100% - 4rem);
  }
}

.category-wrapper {
  display: block;
  white-space: nowrap;
  overflow-x: scroll;
  margin-top: 0.5rem;

  .category {
    display: inline-block;
    font-size: 11px;
    font-weight: $mediumFontWeight;
    text-transform: capitalize;
    padding: 0.25rem 0.65rem;
    background: #f7f7f7;
    margin-right: 0.25rem;
    border-radius: 0.35rem;

    color: lighten($darkBlackColor, 35%);
    border: 1px solid darken($whiteColor, 10.5%);

    span {
      display: inline-block;
      vertical-align: middle;
      max-width: 10rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .ai-icon,
    .unicon /deep/ svg {
      display: inline-block;
      vertical-align: middle;
      height: auto;
      width: 0.85rem;
      margin-right: 0.35rem;
      fill: darken($whiteColor, 35%);
    }

    .ai-icon {
      width: 10px;
    }
  }
}

.show-shadow {
  box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
}
</style>
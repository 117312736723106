<template>
  <div class="card">
    <!--START: Customer-->
    <div class="customer-info">
      <h3>{{ subscriptions.customer }}</h3>
      <p>{{ widgetLanguage.chat.messages.subscriptions.description }}</p>
    </div>
    <!--END: Customer-->

    <!--START: Products-->
    <div
      v-for="subscription in subscriptions.products"
      :key="subscription.id"
      class="subscription-wrapper"
      @click="saveAnswer(subscription)"
    >
      <div class="product-info">
        <img
          v-if="subscription.image_src"
          :src="subscription.image_src"
          :alt="subscription.product_title"
          class="product-image"
        />
        <div class="product-details">
          <h3 class="product-title">{{ subscription.product_title }}</h3>
          <span v-if="subscription.variant_title" class="product-variant">{{
            subscription.variant_title
          }}</span>
          <div class="delivery-date">
            <span>Next order</span>
            <em>{{ getNextDelivery(subscription) }}</em>
          </div>
        </div>
        <div class="frequency-details">
          <span class="product-price"
            >{{ subscription.quantity }} x {{ getItemCost(subscription) }}</span
          >
          <span class="order-frequency">{{
            getOrderFrequency(subscription)
          }}</span>
        </div>
      </div>
    </div>
    <!--END: Products-->
  </div>
</template>
    
<script>
// Importing Services
import "external-svg-loader";

export default {
  name: "SubscriptionsMessage",
  data() {
    return {
      isReadOnly: false,
    };
  },
  props: {
    message: { type: Object, required: true },
  },
  watch: {},
  components: {},
  computed: {
    subscriptions() {
      return this.message.messageData.rechargeData.subscriptions;
    },

    widgetLanguage() {
      return this.languages[this.$store.getters.widgetLanguage].widget;
    },
  },
  created() {},
  methods: {
    // Create a string for order frequency
    getNextDelivery(subscription) {
      const date = new Date(subscription.next_charge_scheduled_at);

      // Get the day, month, and year from the date
      const day = date.getDate();
      const month = date.toLocaleString("default", { month: "long" });
      const year = date.getFullYear();

      // Add the appropriate ordinal suffix to the day
      const dayWithSuffix =
        day +
        ["th", "st", "nd", "rd"][
          day % 10 > 3 || Math.floor((day % 100) / 10) === 1 ? 0 : day % 10
        ];

      // Return the formatted date
      return `${dayWithSuffix} ${month}, ${year}`;
    },

    // Create a string for order frequency
    getOrderFrequency(subscription) {
      let response = `Every ${subscription.order_interval_frequency} ${subscription.order_interval_unit}`;
      if (subscription.order_interval_frequency > 1) response += "s";

      return response;
    },

    // Get the item cost
    getItemCost(subscription) {
      const itemCost = `${this.getCurrency(
        subscription.presentment_currency
      )}${parseInt(subscription.price).toLocaleString()}`;

      return itemCost;
    },

    // Select a subscription
    saveAnswer(subscription) {
      if (!this.isReadOnly) {
        this.isReadOnly = true;

        const payload = {
          selectedSubscription: subscription,
        };

        this.$emit("saveAnswer", payload);
      }
    },
  },
};
</script>
        
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
@import "@/assets/styles/shared/chat.scss";

.card {
  padding: 1rem !important;
  border-radius: 0.5rem !important;
  margin: 0.5rem 0 1rem !important;
  width: calc(95% - 2rem);
}

.customer-info {
  margin-bottom: 1.5rem;

  h3 {
    font-size: $normalFontSize;
    margin-bottom: 0;
  }

  p {
    font-size: $smallerFontSize;
  }
}

.subscription-wrapper {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ececec;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}

.product-info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .product-image {
    display: block;
    width: 3.5rem;
    border-radius: 0.5rem;
    margin-right: 1rem;
  }

  .product-details {
    flex: 1;

    .product-title {
      color: $darkBlackColor;
      font-size: $smallFontSize;
      font-weight: $mediumFontWeight;
      margin-bottom: 0.15rem;
    }

    .product-variant {
      display: block;
      font-size: $smallestFontSize;
      color: lighten($darkBlackColor, 15%);
    }
  }

  .frequency-details {
    text-align: right;

    .product-price {
      display: block;
      font-size: $smallestFontSize;
      color: lighten($darkBlackColor, 15%);
    }

    .order-frequency {
      display: block;
      font-size: $smallestFontSize;
      opacity: 0.75;
      color: lighten($darkBlackColor, 15%);
    }
  }

  .delivery-date {
    margin-top: 0.75rem;

    span {
      margin-bottom: 0.25rem;
      font-size: $smallestFontSize;
      color: lighten($darkBlackColor, 25%);
      margin-right: 0.75rem;
      opacity: 0.75;
    }

    em {
      display: inline-block;
      font-size: $smallestFontSize;
      font-weight: $mediumFontWeight;
      color: lighten($darkBlackColor, 15%);
      background-color: darken($whiteColor, 10%);
      padding: 0.25rem 0.5rem;
      border-radius: 0.5rem;
    }
  }

  .unicon /deep/ svg {
    height: auto;
    width: 1rem;
    fill: $greyColor;
  }
}
</style>
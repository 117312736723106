<template>
  <div class="page-main flex-page">
    <!--START: Header Wrapper-->
    <div
      class="header-wrapper"
      :class="{ 'show-shadow': showOptions.headerShadow }"
    >
      <!--START: Page Header-->
      <RegularHeader
        class="regular-header"
        :data="regularHeader"
      ></RegularHeader>
      <!--END: Page Header-->
    </div>
    <!--END: Header Wrapper-->

    <!--START: Loader-->
    <PageLoader v-if="lodash.isEmpty(company)" :showLoader="true"></PageLoader>
    <!--END: Loader-->

    <!--START: Apps-->
    <div
      ref="sources"
      class="sources-wrapper settings-form card"
      @scroll="sourcesScroll"
    >
      <Shopify></Shopify>
      <Recharge></Recharge>
      <Zendesk></Zendesk>
    </div>
    <!--END: Apps-->

    <!--START: Notification Message-->
    <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->
  </div>
</template>
          
  <script>
// Imoprt libraries
import _ from "lodash";

// Importing components
import RegularHeader from "@/components/dashboard/RegularHeader";
import NotificationMessage from "@/components/modals/NotificationMessage";
import LineLoader from "@/components/loaders/LineLoader";
import PageLoader from "@/components/loaders/PageLoader";

import Shopify from "@/components/dashboard/integrations/Shopify/ViewApp";
import Zendesk from "@/components/dashboard/integrations/Zendesk/ViewApp";
import Recharge from "@/components/dashboard/integrations/Recharge/ViewApp";

// Importing Services
import "external-svg-loader";

export default {
  name: "IntegrationsSettings",
  data() {
    return {
      lodash: _,
      showOptions: {
        headerShadow: false,
        lineLoader: false,
      },
      regularHeader: {
        title: "Integrations",
        description:
          "Manage all the integrations to extend the AI functionality",
      },
      status: {
        show: false,
        status: "success",
        title: "Document deleted",
      },
    };
  },
  props: {},
  watch: {},
  components: {
    RegularHeader,
    NotificationMessage,
    LineLoader,
    PageLoader,
    Shopify,
    Recharge,
    Zendesk,
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },
  },
  async created() {},
  methods: {
    // Event on sourcesScroll scroll
    sourcesScroll() {
      const scrollTop = this.$refs.sources.scrollTop;

      // Check if header needs shadow
      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },
  },
};
</script>
          
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.header-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 1.5rem;

  .regular-header {
    flex: 1;
    max-width: 70%;
    margin-right: 30%;
  }
}

.page-loader-wrapper {
  margin-top: 2rem;
}

/deep/ .line-loader {
  position: fixed;
  z-index: 101;
  bottom: 0;
  left: 0;
}

.show-shadow {
  box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
}
</style>
<template>
  <div class="field-wrapper">
    <div class="field-item" :class="{ error: field.hasError }">
      <input
        type="date"
        :placeholder="field.placeholder"
        :required="field.required"
        :readonly="field.readOnly ? field.readOnly : false"
        :disabled="field.readOnly"
        :min="field.minDates != undefined ? field.minDates : null"
        :max="field.maxDates != undefined ? field.maxDates : null"
        :class="[
          field.class != undefined ? field.class : '',
          { error: field.hasError },
        ]"
        @focus="field.hasError = false"
        @selected="fieldChanged()"
        v-model="field.value"
      />
    </div>
  </div>
</template>
  
<script>
export default {
  name: "Datepicker",
  data() {
    return {};
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  methods: {
    fieldChanged() {
      this.$emit("fieldChanged");
    },
  },
};
</script>
  
<style scoped lang="scss">
</style>